import React from 'react';
import { Router } from './Routes/Routes';
import './containers/Home/Home.css';
import AppProvider from './containers/AppProvider/AppProvider';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './react-query/useQueryClientGet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { RouterProvider } from 'react-router';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './utils/AppInsights/AppInsightsService';

function App() {
  const router = Router({ providers: <AppProvider /> });

  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ToastContainer
            position="bottom-center"
            theme="colored"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </>
  );
}

export default App;
