import { LogLevel } from '@azure/msal-browser';

export const msalFeatureFlag = process.env.REACT_APP_USE_MSAL || false;

export const appSettings = {
  clientId: process.env.REACT_APP_MSAL_CLIENT as string,
  tenantId: process.env.REACT_APP_MSAL_TENANT as string,
  redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL as string,
  logoutUrl: process.env.REACT_APP_MSAL_LOGOUT_URL as string
};

export const scopes = [`api://${appSettings.clientId}/AllowAccess`];

export const msalConfig = {
  auth: {
    clientId: appSettings.clientId,
    authority: `https://login.microsoftonline.com/${appSettings.tenantId}/`,
    postLogoutRedirectUri: '/splash'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
          // Do nothing
        }
      }
    }
  }
};
