import { useContext, useMemo, useEffect, FunctionComponent, ReactElement, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { emailOptOut, useGetIsOptedOut } from '../../customHooks/UserHttpServices';
import { queryKeys } from '../../react-query/queryKeys';
import { Store } from '../../Store';
import { HomeNoGroup } from './HomeNoGroup';
import { HomeWithGroup } from './HomeWithGroup';
import { publish } from '../../utils/Events/CustomEvents';
import { useLocation } from 'react-router-dom';

interface HomeProps {
  isEmailOptOut?: boolean;
}

export interface HomeComponentProps {
  successMessage?: string | ReactElement;
  clearSuccessMessage: () => void;
}

export const Home = ({ isEmailOptOut = false }: HomeProps) => {
  const { selectedUserGroup } = useContext(Store);
  const queryClient = useQueryClient();
  const [successMessage, setSuccessMessage] = useState<HomeComponentProps['successMessage']>();
  const location = useLocation();

  const { mutate: optOutOfEmails } = useMutation(emailOptOut, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.accountSettings);
      setSuccessMessage(
        <>
          You have successfully opted out of receiving all email alerts. If this was a mistake or if you&apos;d like to
          manage these settings in the future, you can do so in the Account &amp; Settings menu.&nbsp;
          <span
            className="underline text-blue cursor-pointer"
            onClick={() => publish('toggleAccountSettingsModal', true)}
          >
            Manage Email Alerts
          </span>
        </>
      );
    }
  });

  const { data: isOptedOut, isFetched } = useGetIsOptedOut({
    enabled: isEmailOptOut
  });

  useEffect(() => {
    if (isEmailOptOut && isFetched && isOptedOut === false) {
      optOutOfEmails({});
    }
  }, [isEmailOptOut, isFetched, isOptedOut]);

  useEffect(() => {
    if (location.state && location.state.successMessage) {
      setSuccessMessage(location.state.successMessage);
    }
  }, [location.state]);

  const ComponentName: FunctionComponent<HomeComponentProps> = useMemo(() => {
    if (selectedUserGroup.id > 0) {
      return HomeWithGroup;
    }
    return HomeNoGroup;
  }, [selectedUserGroup]);

  return (
    <ComponentName
      successMessage={successMessage}
      clearSuccessMessage={() => setSuccessMessage(undefined)}
    />
  );
};
