//import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=375088a7-b450-4051-b97a-2af5181911bb;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=9c8e2b5f-c8d0-42a9-a4bb-03bed0854f42',
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
appInsights.loadAppInsights();

// To add Telemetry data use the following code and import ITelemetryItem

// appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
//   env.tags = env.tags || {};
//   env.tags['ai.cloud.role'] = '<app-role-frontend>';
//   //custom props
//   env.data = env.data || {};
//   env.data['ms-appName'] = '<frontend-name>';
//   env.data['ms-user'] = '<frontend-auth-user>';
//   env.data['ms-userid'] = '<frontend-auth-userid>';
// });

export { reactPlugin, appInsights };
