import { FunctionComponent, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { DocumentsModuleOutletInterface } from './DocumentsModuleContainer';

const DocumentsModuleIndex: FunctionComponent = () => {
  const { documentGroups } = useOutletContext<DocumentsModuleOutletInterface>();
  const navigate = useNavigate();
  useEffect(() => {
    if (documentGroups.length > 0) {
      navigate(`./${documentGroups[0].id}`);
    }
  }, [documentGroups, navigate]);

  return (
    <>
      <div>
        {documentGroups && documentGroups.length === 0 ? (
          <div>No document groups have been created yet.</div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default DocumentsModuleIndex;
